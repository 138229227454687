import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap, map, tap } from 'rxjs/operators';
import { FilingTypeKey } from '../models/filing-type-key.enum';
import { FilingType } from '../models/filing-type.model';
import { Role, RoleKey } from '../models/role.model';
import { WorkflowType } from '../models/workflow-type.model';
import { Workflow } from '../models/workflow.model';
import { QueryService } from './query.service';
import _ from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class ConfigDataService {

  static BULK_ACTION_NOTIFICATION = {
    label: 'Reviewer Notifications/Bulk Record Action Notification',
    toggleName: 'configsBulkActionRecordsManagementNotification',
    modelName: 'bulkActionRecordsManagementNotification',
    emailModel: 'bulkActionRecordsManagementRecipients',
    emailLabel: 'Email Recipients',
    emailModelInputType: 'textarea',
    emailModelRequired: true,
    modelTextName: 'bulkActionRecordsManagementText',
    modelTextLabel: 'Initiation Notification Message',
    calculateModelTextReadOnly: true,
    textParameters: [
      'bulkActionUsername',
      'bulkActionUserEmail',
      'bulkActionCount',
      'bulkActionGroups',
      'bulkActionExecutionDateTime',
    ],
    modelText2Name: 'bulkActionRecordsManagementCancelationText',
    modelText2Label: 'Cancelation Notification Message',
    calculateModelText2ReadOnly: true,
    text2Parameters: [
      'bulkActionCancelUsername',
      'bulkActionCancelUserEmail',
      'bulkActionUsername',
      'bulkActionUserEmail',
      'bulkActionCount',
      'bulkActionGroups',
      'bulkActionRequestDateTime',
    ],
  };

  static LANDING_PAGE_USER_RESOURCES = 'landingPage-userResources';
  static LANDING_PAGE_HELP = 'landingPage-help';
  static LANDING_PAGE_SECURITY = 'landingPage-security';
  static LANDING_PAGE_PRIVACY = 'landingPage-privacy';
  static LANDING_PAGE_ACCESSIBILITY = 'landingPage-accessibility';
  static AGENCY_SUPPORT_HELP_DOCUMENT = 'agencySupportHelp';
  
  static OGE_OVERSIGHT_LANDING_PAGE_DOCUMENTS = [
    {
      label: 'User Resource Link',
      modelName: ConfigDataService.LANDING_PAGE_USER_RESOURCES,
      required: true
    },
    {
      label: 'Security Link',
      modelName: ConfigDataService.LANDING_PAGE_SECURITY,
      required: false
    },
    {
      label: 'Privacy Link',
      modelName: ConfigDataService.LANDING_PAGE_PRIVACY,
      required: false
    },
    {
      label: 'Accessibility Link',
      modelName: ConfigDataService.LANDING_PAGE_ACCESSIBILITY,
      required: false
    },
    {
      label: 'Help Link',
      modelName: ConfigDataService.LANDING_PAGE_HELP,
      required: false
    }
  ];


  AGENCY_GENERAL_NOTIFICATIONS = [
    {
      label: 'Integrity Notifications/User Provisioning Notification',
      toggleName: 'configsUserProvisioning',
      modelName: 'userProvisioning',
      modelTextName: 'userProvisioningText',
      textParameters: ['name', 'passwordResetUrl', 'accessType', 'cloakedId'],
    },
    {
      label: 'Integrity Notifications/Cloaked User Provisioning Notification',
      toggleName: 'configsUserProvisioningCloaked',
      modelName: 'userProvisioningCloaked',
      modelTextName: 'userProvisioningCloakedText',
      textParameters: ['name', 'passwordResetUrl', 'accessType', 'cloakedId'],
    },
    {
      label: 'Integrity Notifications/Annual Profile Update',
      toggleName: 'configsAnnualProfileUpdate',
      modelName: 'annualProfileUpdate',
      modelTextName: 'annualProfileUpdateText',
      textParameters: ['name', 'passwordResetUrl'],
    },
    {
      label: 'Filer Notifications/Report Assignment',
      modelName: 'reportAssignment',
      modelGeneralName: 'reportAssignmentGeneral',
      modelPas: 'reportAssignmentPas',
      modelTextName: 'reportAssignmentText',
      textParameters: [
        'filingFiler',
        'filingFilerName',
        'filingYear',
        'filingItem',
        'filingDate',
      ],
      attachment: true,
    },
    {
      label: 'Filer Notifications/Filing Reminders Assignment',
      toggleName: 'configsAssignmentReminder',
      modelName: 'assignmentReminder',
      modelTextName: 'assignmentReminderText',
      textParameters: [
        'filingYear',
        'filingItem',
        'filingPosition',
        'filingDate',
      ],
      frequencyModel: 'assignmentReminderFrequency',
    },
    {
      label: 'Filer Notifications/Past Due Reminders',
      toggleName: 'configsPastDueReminder',
      modelName: 'pastDueReminder',
      modelTextName: 'pastDueReminderText',
      textParameters: [
        'filingYear',
        'filingItem',
        'filingPosition',
        'filingDate',
      ],
      frequencyModel: 'pastDueReminderFrequency',
    },
    {
      label: 'Filer Notifications/Amend Report Notification',
      modelName: 'amendReport',
      modelTextName: 'amendReportText',
      textParameters: [
        'filingFiler',
        'filingYear',
        'filingItem',
        'filingDate',
        'filingReporterName',
      ],
    },
    {
      label: 'Filer Notifications/Amend Report Reminder Notification',
      toggleName: 'configsAmendReportReminder',
      modelName: 'amendReportReminder',
      modelTextName: 'amendReportReminderText',
      textParameters: [
        'filingFiler',
        'filingYear',
        'filingItem',
        'filingDate',
        'filingReporterName',
      ],
    },
    {
      label: 'Filer Notifications/Filing Certification Notification',
      toggleName: 'configsCertification',
      modelName: 'certification',
      modelTextName: 'certificationText',
      textParameters: [
        'filingYear',
        'filingItem',
        'filingPosition',
        'filingAgency',
        'filingCertifierName',
      ],
    },
    {
      label: 'Filer Notifications/Agency Closes Report',
      toggleName: 'configsCloses',
      modelName: 'closes',
      modelTextName: 'closesText',
      textParameters: [
        'filingYear',
        'filingItem',
        'filingPosition',
        'filingAgency',
        'filingCloserName',
      ],
    },
    {
      label: 'Reviewer Notifications/Reporting Pending Action Notification',
      toggleName: 'configsPendingAction',
      modelName: 'pendingAction',
      modelTextName: 'pendingActionText',
      textParameters: [
        'filingYear',
        'filingItem',
        'filingPosition',
        'filingName',
        'filingRole',
        'filingAgency',
      ],
    },
    {
      label: 'Reviewer Notifications/Pending Action Reminders',
      toggleName: 'configsPendingActionReminder',
      modelName: 'pendingActionReminder',
      modelTextName: 'pendingActionReminderText',
      textParameters: [
        'filingYear',
        'filingItem',
        'filingPosition',
        'filingName',
        'filingRole',
        'filingDays',
        'filingAgency',
      ],
      frequencyModel: 'pendingActionReminderFrequency',
    },
    // EFEDS-6263
    ConfigDataService.BULK_ACTION_NOTIFICATION
  ];
  
  FILING_EXTENSION_NOTIFICATIONS = [
    {
      label: 'Reviewer Notifications/Request For Extension Notification',
      toggleName: 'configsFilerExtensionRequest',
      modelName: 'filerExtensionRequest',
      emailModel: 'filerExtensionRequestRecipients',
      emailLabel: 'Alternate Recipient Email Address',
      emailModelInputType: 'textarea',
      emailModelRequired: false,
      modelTextName: 'filerExtensionRequestText',
      textParameters: ['filingName', 'filingYear', 'filingItem'],
    },
    {
      label: 'Reviewer Notifications/Request For Extension Withdrawn',
      toggleName: 'configsFilerExtensionRequestWithdrawn',
      modelName: 'filerExtensionRequestWithdrawn',
      emailModel: 'filerExtensionRequestWithdrawnRecipients',
      emailLabel: 'Alternate Recipient Email Address',
      emailModelInputType: 'textarea',
      emailModelRequired: false,
      modelTextName: 'filerExtensionRequestWithdrawnText',
      textParameters: ['filingName', 'filingYear', 'filingItem'],
    },
    {
      label: 'Reviewer Notifications/Request For Extension Reminders',
      toggleName: 'configsFilerExtensionRequestReminder',
      modelName: 'filerExtensionRequestReminder',
      emailModel: 'filerExtensionRequestReminderRecipients',
      emailLabel: 'Alternate Recipient Email Address',
      emailModelInputType: 'textarea',
      emailModelRequired: false,
      modelTextName: 'filerExtensionRequestReminderText',
      textParameters: ['filingName', 'filingYear', 'filingItem'],
      frequencyModel: 'filerExtensionRequestReminderFrequency',
    },
    {
      label: 'Filer Notifications/Extension Decision Notification',
      toggleName: 'configsReviewerExtensionRequestDecision',
      modelName: 'reviewerExtensionRequestDecision',
      emailModelInputType: 'textarea',
      emailModelRequired: true,
      modelTextName: 'reviewerExtensionRequestDecisionText',
      textParameters: [
        'filingYear',
        'filingItem',
        'filingExtension',
        'filingDue',
        'reviewerName',
        'extensionDecision',
        'extensionComment',
      ],
    },
  ];

  GROUP_NOTIFICATIONS = [
    ConfigDataService.BULK_ACTION_NOTIFICATION
  ];
  
  
  NOTIFICATION_PARAMETERS = {
    name: {
      name: 'name',
      desc: 'Name of the person receiving the notification.',
    },
    passwordResetUrl: {
      name: 'passwordResetUrl',
      desc: 'MAX URL for password resets',
    },
    accessType: { name: 'accessType', desc: 'e.g. Filer, Nominee' },
    cloakedId: {
      name: 'cloakedId',
      desc: 'Cloaked ID, for Designees and Nominees only.',
    },

    // filing params
    filingName: { name: 'name', desc: 'Filer name of filing.' },
    filingFiler: {
      name: 'filer',
      desc: 'Filer that has been assigned the report.',
    },
    filingFilerName: {
      name: 'name',
      desc: 'The person who made the assignment.',
    },
    filingReporterName: {
      name: 'name',
      desc: "Name of whomever's action triggered the report (i.e. returned by).",
    },
    filingCertifierName: {
      name: 'name',
      desc: "Name of whomever's action triggered the report (i.e. report certifier).",
    },
    filingCloserName: {
      name: 'name',
      desc: "Name of whomever's action triggered the report (i.e. report closer).",
    },
    filingYear: { name: 'year', desc: 'Year of filing' },
    filingItem: {
      name: 'item',
      desc: ' Item type of filing (e.g. New Entrant, Annual).',
    },
    filingDate: { name: 'date', desc: 'Due date of filing.' },
    filingPosition: { name: 'position', desc: 'Position of filer of filing.' },
    filingAgency: { name: 'agency', desc: 'Agency of filing.' },
    filingRole: {
      name: 'role',
      desc: "User's workflow role requiring action (e.g. Supervisor, Ethics Official).",
    },
    filingDays: {
      name: 'days',
      desc: 'Number of days since action (i.e. age).',
    },

    // EFEDS-6263 bulk action notification
    bulkActionCancelUsername: {
      name: 'bulkActionCancelUsername',
      desc: 'Name of the session user who canceled the bulk action request.',
    },
    bulkActionCancelUserEmail: {
      name: 'bulkActionCancelUserEmail',
      desc: 'Email of the session user who canceled the bulk action request.',
    },
    bulkActionUsername: {
      name: 'bulkActionUsername',
      desc: 'Name of the session user who initiated the bulk action request.',
    },
    bulkActionUserEmail: {
      name: 'bulkActionUserEmail',
      desc: 'Email of the session user who initiated the bulk action request.',
    },
    bulkActionCount: {
      name: 'bulkActionCount',
      desc: 'The count of reports included in the records management bulk action request.',
    },
    bulkActionExecutionDateTime: {
      name: 'bulkActionExecutionDateTime',
      desc: 'The approximate date and time that the bulk action will be processed.',
    },
    bulkActionGroups: {
      name: 'bulkActionGroups',
      desc: 'The groups to which  reports included in the bulk action belong.',
    },
    bulkActionRequestDateTime: {
      name: 'bulkActionRequestDateTime',
      desc: 'The date and time that the bulk action cancelation request was initiated.',
    },
    reviewerName: {
      name: 'reviewerName',
      desc: 'Name of the reviewer who took the action',
    },
    filingExtension: {
      name: 'extension',
      desc: 'Number of days of the filing extension',
    },
    filingDue: { name: 'due', desc: 'Due date of filing.' },
    extensionDecision: {
      name: 'decision',
      desc: 'Whether the extension was "Approved" or "Denied"',
    },
    extensionComment: {
      name: 'comment',
      desc: "The comment associated with the reviewer's decision regarding the filing extension",
    },
  };

  OGE_OVERSIGHT_NOTIFICATIONS = [
    {
      label: 'Integrity Notifications/User Provisioning Notification',
      toggleName: 'configsSpecialUserProvisioning',
      modelName: 'specialUserProvisioning',
      modelTextName: 'specialUserProvisioningText',
      textParameters: ['name', 'passwordResetUrl'],
    },
    {
      label: 'Integrity Notifications/Designee Provisioning Notification',
      toggleName: 'configsSpecialUserProvisioningCloaked',
      modelName: 'specialUserProvisioningCloaked',
      modelTextName: 'specialUserProvisioningCloakedText',
      textParameters: [
        'name',
        'filingFilerName',
        'cloakedId',
        'passwordResetUrl',
      ],
    },
    {
      label: 'Reviewer Notification/Nominee Draft Ready For PPO/PTT',
      toggleName: 'configsnomineeDraftReadyPPO',
      modelName: 'nomineeDraftReadyPPO',
      modelTextName: 'nomineeDraftReadyPPOText',
      textParameters: [
        'filingYear',
        'filingItem',
        'filingPosition',
        'filingName',
        'filingRole',
        'filingAgency',
      ],
      emailModel: 'nomineeDraftReadyPPOAddress',
    },
    {
      label: 'Reviewer Notification/Nominee Draft Ready For WHC',
      toggleName: 'configsnomineeDraftReadyWHC',
      modelName: 'nomineeDraftReadyWHC',
      modelTextName: 'nomineeDraftReadyWHCText',
      textParameters: [
        'filingYear',
        'filingItem',
        'filingPosition',
        'filingName',
        'filingRole',
        'filingAgency',
      ],
      emailModel: 'nomineeDraftReadyWHCAddress',
    },
    {
      label: 'Reviewer Notification/Pending Action',
      toggleName: 'configsReviewerPendingAction',
      modelName: 'reviewerPendingAction',
      modelTextName: 'reviewerPendingActionText',
      textParameters: [
        'filingYear',
        'filingItem',
        'filingPosition',
        'filingName',
        'filingRole',
        'filingAgency',
      ],
    },
    {
      label: 'Reviewer Notification/Pending Action Reminder',
      toggleName: 'configsReviewerPendingActionReminder',
      modelName: 'reviewerPendingActionReminder',
      modelTextName: 'reviewerPendingActionReminderText',
      textParameters: [
        'filingYear',
        'filingItem',
        'filingPosition',
        'filingName',
        'filingRole',
        'filingDays',
        'filingAgency',
      ],
    },
    {
      label: 'Reviewer Notification/OGE Preclearance',
      roleOptions: 'preclearRoleOptions',
      roleModelName: 'ogePreclearanceRoleRecipients',
      modelTextName: 'ogePreclearanceText',
      textParameters: [
        'filingYear',
        'filingItem',
        'filingPosition',
        'filingName',
        'filingAgency',
      ],
    },
    {
      label: 'Reviewer Notifications/OGE Certification',
      roleOptions: 'certConfirmedRoleOptions',
      roleModelName: 'ogeCertificationRoleRecipients',
      modelTextName: 'ogeCertificationText',
      textParameters: [
        'filingYear',
        'filingItem',
        'filingPosition',
        'filingName',
      ],
    },
    {
      label: 'Reviewer Notifications/OGE Nominee Certification',
      roleOptions: 'certNomineeRoleOptions',
      roleModelName: 'ogeNomineeCertificationRoleRecipients',
      modelTextName: 'ogeNomineeCertificationText',
      textParameters: [
        'filingYear',
        'filingItem',
        'filingPosition',
        'filingName',
      ],
    },
    {
      label: 'Reviewer Notifications/OGE Closes Report',
      roleOptions: 'noCertRoleOptions',
      roleModelName: 'ogeClosesRoleRecipients',
      modelTextName: 'ogeClosesText',
      textParameters: [
        'filingYear',
        'filingItem',
        'filingPosition',
        'filingName',
      ],
    },
  ];

  SYSTEM_SETTINGS = {
    enableExtensionRequestOption: false,
    enableFormChange3: true,
  };

  public static OGE_GROUP_LIMIT_REACHED =
    'The OGE Oversight Agency may contain no more than two top-level groups, one for Nominees and one for all other filers. Additional groups, if needed, should be added as subgroups.';

  constructor(private queryService: QueryService) {}

  public filingTypesArray: FilingType[];
  public filingTypesMap: Map<string, FilingType>;

  public workflowTypesArray: WorkflowType[];
  public workflowTypesMap: Map<string, WorkflowType>;
  public workflowTypesByFilingType: Map<FilingTypeKey, WorkflowType[]>;

  public workflowsMap: Map<WorkflowType, Workflow[]>;
  public workflowsRoles: Map<WorkflowType, RoleKey[]>;

  public rolesArray: Role[];
  public rolesMap: Map<RoleKey, Role>;

  private initialized = false;

  init(): Observable<null> {
    if (this.initialized) return of(null);

    return this.queryService.getFilingTypes().pipe(
      mergeMap((filingTypes: FilingType[]) => {
        this.filingTypesArray = _.sortBy(filingTypes, 'key').reverse();
        this.filingTypesMap = new Map<string, FilingType>();
        filingTypes.forEach((type) =>
          this.filingTypesMap.set(type.surrogateKey, type)
        );

        return this.queryService.getWorkflowTypes(this.filingTypesMap);
      }),
      mergeMap((workflowTypes) => {
        this.workflowTypesArray = workflowTypes;
        this.workflowTypesMap = new Map<string, WorkflowType>();
        workflowTypes.forEach((type) =>
          this.workflowTypesMap.set(type.surrogateKey, type)
        );

        this.workflowTypesByFilingType = new Map<
          FilingTypeKey,
          WorkflowType[]
        >();
        this.filingTypesArray.forEach((filingType) => {
          this.workflowTypesByFilingType.set(
            filingType.key,
            this.getWorkflowTypes(filingType.key)
          );
        });

        return this.queryService.getAllRoles();
      }),
      mergeMap((roles) => {
        this.rolesArray = roles.map((role) => Role.fromJson2(role));
        this.rolesMap = new Map<RoleKey, Role>();
        this.rolesArray.forEach((role) => {
          this.rolesMap.set(role.surrogateKey, role);
        });

        return this.queryService.getWorkflows(this.workflowTypesArray);
      }),
      map((workflows) => {
        this.workflowsMap = workflows;
        this.workflowsRoles = new Map<WorkflowType, RoleKey[]>();
        this.workflowsMap.forEach((states, workflow) => {
          this.workflowsRoles.set(
            workflow,
            states.reduce((list, state) => {
              const roleKey = state.role?.surrogateKey;
              if (roleKey && !list.includes(roleKey)) {
                list.push(roleKey);
              }

              return list;
            }, [] as RoleKey[])
          );
        });

        return null;
      }),
      tap(() => {
        this.initialized = true;
      })
    );
  }

  public getWorkflowTypes(filingType: FilingTypeKey): WorkflowType[] {
    return this.workflowTypesArray.reduce((list, workflow) => {
      if (workflow.filingType?.key == filingType) {
        list.push(workflow);
      }
      return list;
    }, [] as WorkflowType[]);
  }

  public getAgencyNotifications(allowExtensionRequest: boolean): any {
   return !allowExtensionRequest ?
      this.AGENCY_GENERAL_NOTIFICATIONS
      : [...this.AGENCY_GENERAL_NOTIFICATIONS, ...this.FILING_EXTENSION_NOTIFICATIONS];
  }
  
  public getAllNotifications(isAgency: boolean): any {
    return isAgency 
      ? [...this.FILING_EXTENSION_NOTIFICATIONS, ...this.OGE_OVERSIGHT_NOTIFICATIONS, ...this.AGENCY_GENERAL_NOTIFICATIONS] 
      : [...this.FILING_EXTENSION_NOTIFICATIONS, this.GROUP_NOTIFICATIONS];
  }
  
  /* OGE-8018 */
  public get enableExtensionRequestOption(): boolean {
    return this.SYSTEM_SETTINGS.enableExtensionRequestOption;
  }

  /**
   * Form change 3 2024 - allow for configurable turn on
   * OGE-8372, OGE-8373, OGE-8604, OGE-8605, OGE-8640, OGE-8419
   */
  public get enableFormChange3Option(): boolean {
    return this.SYSTEM_SETTINGS.enableFormChange3;
  }
  
  public get loaded(): boolean {
    return this.initialized;
  }
}
